import React from 'react'

const FormStateContext = React.createContext()
const FormDispatchContext = React.createContext()

function formReducer(state, action) {
  switch (action.type) {
    case 'setName': {
      return {
        ...state,
        name: action.payload,
      }
    }
    case 'setMethod': {
      return {
        ...state,
        method: action.payload,
      }
    }
    case 'setDate': {
      return {
        ...state,
        date: action.payload,
      }
    }
    case 'setTime': {
      return {
        ...state,
        time: action.payload,
      }
    }
    case 'setCounty': {
      return {
        ...state,
        county: action.payload,
      }
    }
    case 'setCanvas': {
      return {
        ...state,
        canvas: action.payload,
      }
    }
    case 'setDoSubmit': {
      return {
        ...state,
        doSubmit: action.payload,
      }
    }
    case 'reset': {
      return {
        ...state,
        name: '',
        method: '',
        date: null,
        time: '',
        county: '',
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

/* eslint-disable-next-line react/prop-types */
function FormProvider({ children }) {
  const [state, dispatch] = React.useReducer(formReducer, {
    name: '',
    method: '',
    date: null,
    time: '',
    county: '',
    canvas: null,
    doSubmit: false
  })

  return (
    <FormStateContext.Provider value={state}>
      <FormDispatchContext.Provider value={dispatch}>
        {children}
      </FormDispatchContext.Provider>
    </FormStateContext.Provider>
  )
}

function useFormState() {
  const context = React.useContext(FormStateContext)

  if (context === undefined) {
    throw new Error('useFormState must be used within a FormProvider')
  }

  return context
}

function useFormDispatch() {
  const context = React.useContext(FormDispatchContext)

  if (context === undefined) {
    throw new Error('useFormDispatch must be used within a FormProvider')
  }

  return context
}

export { FormProvider, useFormState, useFormDispatch }
