import React from "react";

function CallToAction() {
  return (
    <section className="section-cta page-section">
      <div className="grid-container">
        <h2>Need help making a plan?</h2>

        <div className="cta-columns">
          <div className="cta-column">
            <div>
              <p>Sign up for election reminders and more at Vote.org.</p>
            </div>

            <div className="cta-column__actions">
              <a
                href="https://vote.org"
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit Vote.org
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CallToAction;
