import React, { useState, useEffect, useCallback, useRef } from 'react'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import easydropdown from 'easydropdown'
import { useFormState } from './FormContext'
import api from './common/api'

const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))

const calendarOptions = [
  {
    label: 'Apple iCal',
    value: 'ics'
  },
  {
    label: 'Google',
    value: 'google'
  },
  {
    label: 'Outlook',
    value: 'outlook'
  },
  {
    label: 'Yahoo Mail',
    value: 'yahoo'
  },
]

function CalendarSelect({ disabled, setCalendarType }) {
  const el = useRef(null)

  useEffect(() => {
    if (el.current) {
      easydropdown(el.current, {
        behavior: {
          liveUpdates: true
        },
        callbacks: {
          onSelect: (nextValue) => {
            setCalendarType(nextValue)
            el.current.value = null
            easydropdown(el.current).refresh()
          }
        }
      })
    }
  }, [el, setCalendarType])

  return (
    <select ref={el} disabled={disabled} defaultValue="">
      <option value="" data-placeholder>Add To My Cal</option>

      {calendarOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
    </select>
  )
}

function FormActions() {
  const [actionsEnabled, setActionsEnabled] = useState(false)
  const [calendarType, setCalendarType] = useState(null)
  const { name, method, date, time, county, canvas, doSubmit } = useFormState()

  useEffect(() => {
    const invalidByMail = [name, method, date, county].some(x => x === '' || x === null)
    const invalid = [name, method, date, time, county].some(x => x === '' || x === null)

    if (method === 'By Mail') {
      setActionsEnabled(!invalidByMail)
    } else {
      setActionsEnabled(!invalid)
    }
  }, [name, method, date, time, county])

  const handleSubmit = async () => {
    setActionsEnabled(false)

    if (doSubmit) {
      const submissionImage = await new Promise((resolve) => {
        return canvas.toBlob(resolve)
      })

      const data = new FormData()
      data.append('name', name)
      data.append('method', method)
      data.append('date', format(date, 'yyyy-MM-dd'))
      data.append('county', county)
      data.append('image', submissionImage)

      if (method !== 'By Mail' && time) {
        data.append('time', time)
      }

      await api.submit(data)
    }

    if (isMobile) {
      const image = canvas.toDataURL('image/png', 1.0)

      const body = `
        <!DOCTYPE html>
        <html lang="en">
          <head>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
          </head>
          <body>
            <img src="${image}" style="max-width: 100%" />
          </body>
        </html>
      `
      const x = window.open()
      x.document.open()
      x.document.write(body)
      x.document.close()
    } else {
      const image = canvas.toDataURL('image/png', 1.0).replace('image/png', 'image/octet-stream')
      const link = document.createElement('a')
      link.download = 'vote-plan.png'
      link.href = image
      link.click()
    }

    setActionsEnabled(true)
  }

  const handleAddToCalendar = useCallback(async (type) => {
    setActionsEnabled(false)

    const datetimeTime = method === 'By Mail' ? '9:00 AM' : time
    const parsedDatetime = parse(datetimeTime, 'h:mm a', date)

    const data = new FormData()
    data.append('type', type)
    data.append('name', name)
    data.append('method', method)
    data.append('date', format(date, 'yyyy-MM-dd'))
    data.append('county', county)
    data.append('datetime', parsedDatetime.toISOString())
    data.append('tz', Intl.DateTimeFormat().resolvedOptions().timeZone)

    if (method !== 'By Mail' && time) {
      data.append('time', time)
    }

    const res = await api.calendar(data)

    if (res.result) {
      if (isMobile) {
        window.location.href = res.result
      } else {
        const link = document.createElement('a')

        if (type === 'ics') {
          link.download = 'vote-plan.ics'
        } else {
          link.target = '_blank'
        }

        link.href = res.result
        link.click()
      }
    }

    setActionsEnabled(true)
  }, [county, date, method, name, time])

  useEffect(() => {
    if (calendarType !== null) {
      handleAddToCalendar(calendarType)
      setCalendarType(null)
    }
  }, [calendarType, handleAddToCalendar])

  return (
    <div className="generator-actions">
      <button type="button" className="btn-primary" onClick={handleSubmit} disabled={!actionsEnabled}>Download</button>
      <div className={`calendar-action styled-dropdown ${!actionsEnabled && 'disabled'}`}>
        <CalendarSelect
          disabled={!actionsEnabled}
          setCalendarType={setCalendarType}
        />
      </div>
    </div>
  )
}

export default FormActions
