import React from 'react'

function Content() {
  return (
    <section className="section-content page-section">
      <div className="grid-container">
        <p>Make a <em className="em-1">PLAN</em>, share a <em className="em-2">PLAN</em>, and let’s <em className="em-3">VOTE!</em></p>
        <p className="heading">FACT:</p>
        <p>If you make a plan to vote, you are 200% more likely to actually vote. <sup>1</sup></p>
        <p className="heading">ANOTHER FACT:</p>
        <p>People who share their voting plan significantly influence others to vote. <sup>2</sup></p>
        <p className="closing">
          <span>
            <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 30">
              <path d="M33 14.802L18.562.367v8.29H0v12.289h18.563v8.29L33 14.802z" fill="#000"/>
            </svg>
          </span>
          Simply put, you can start a voting domino effect. It starts with making a plan. What’s yours?
        </p>

        <div className="sources">
          <p className="source">1 <a href="https://scholar.harvard.edu/files/todd_rogers/files/do_you_have_a_voting_plan_0.pdf?m=1361998148" target="_blank" rel="noopener noreferrer">Here’s the science.</a></p>
          <p className="source">2 <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3834737/" target="_blank" rel="noopener noreferrer">Again, this is actual science.</a></p>
        </div>
      </div>
    </section>
  )
}

export default Content
