import React, { useEffect, useRef } from "react";
import "date-fns";
import easydropdown from "easydropdown";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { useFormState, useFormDispatch } from "./FormContext";

const methodOptions = [
  {
    label: "In Person",
    value: "In Person",
  },
  {
    label: "By Mail",
    value: "By Mail",
  },
  {
    label: "Early In Person",
    value: "Early In Person",
  },
];

const timeOptions = (() => {
  const times = [];
  let startTime = new Date(2020, 10, 3, 6, 0);
  const endTime = new Date(2020, 10, 3, 21, 0);

  while (endTime >= startTime) {
    const time = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    }).format(startTime);

    times.push({
      label: time,
      value: time,
    });

    startTime.setMinutes(startTime.getMinutes() + 30);
  }

  return times;
})();

function Select({ placeholder, options, handleChange }) {
  const el = useRef(null);

  useEffect(() => {
    if (el.current) {
      easydropdown(el.current, {
        behavior: {
          openOnFocus: true,
        },
        callbacks: {
          onSelect: (nextValue) => handleChange(nextValue),
        },
      });
    }
  }, [el, handleChange]);

  return (
    <select ref={el}>
      <option value="" data-placeholder>
        {placeholder}
      </option>

      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

function Form() {
  const { name, method, date, time, county, doSubmit } = useFormState();
  const dispatch = useFormDispatch();

  const handleNameChange = (e) => {
    dispatch({ type: "setName", payload: e.target.value });
  };

  const handleMethodChange = (method) => {
    dispatch({ type: "setMethod", payload: method });

    if (method === "In Person") {
      dispatch({ type: "setDate", payload: new Date(2024, 10, 5) });
    } else {
      dispatch({ type: "setDate", payload: null });
    }
  };

  const handleDateChange = (date) => {
    dispatch({ type: "setDate", payload: date });
  };

  const handleTimeChange = (time) => {
    dispatch({ type: "setTime", payload: time });
  };

  const handleCountyChange = (e) => {
    dispatch({ type: "setCounty", payload: e.target.value });
  };

  const handleDoSubmitChange = (e) => {
    dispatch({ type: "setDoSubmit", payload: e.target.checked });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form className="vote-form">
        <div
          className={`form-field form-field--name ${name !== "" && "filled"}`}
        >
          <input
            type="text"
            placeholder="First Name"
            value={name}
            onChange={handleNameChange}
            maxLength="13"
          />
        </div>

        <div
          className={`form-field form-field--method styled-dropdown ${method !== "" && "filled"}`}
        >
          <Select
            placeholder="Method"
            options={methodOptions}
            handleChange={handleMethodChange}
          />
        </div>

        <div className={`form-field form-field--date ${date && "filled"}`}>
          <DatePicker
            autoOk
            emptyLabel="Date"
            disablePast
            format="MMM. d"
            value={date}
            onChange={handleDateChange}
            shouldDisableDate={(x) => x > new Date(2024, 10, 5)}
          />
        </div>

        {method !== "By Mail" && (
          <div
            className={`form-field form-field--time styled-dropdown ${time !== "" && "filled"}`}
          >
            <Select
              placeholder="Time"
              options={timeOptions}
              handleChange={handleTimeChange}
            />
          </div>
        )}

        <div
          className={`form-field form-field--county ${county !== "" && "filled"}`}
        >
          <input
            type="text"
            placeholder="County or City"
            value={county}
            onChange={handleCountyChange}
            maxLength="13"
          />
        </div>

        <div className="form-checkbox">
          <input
            id="doSubmit"
            type="checkbox"
            checked={doSubmit}
            onChange={handleDoSubmitChange}
          />

          <label htmlFor="doSubmit">
            <div>
              <div className="form-checkbox-label--primary">
                Yes, you may share my plan.
              </div>
              <div className="form-checkbox-label--secondary">
                (We take privacy seriously and never save any personal
                information).
              </div>
            </div>
          </label>
        </div>
      </form>
    </MuiPickersUtilsProvider>
  );
}

export default Form;
