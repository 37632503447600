import React, { useState, useEffect, useRef } from 'react'
import format from 'date-fns/format'
import jump from 'jump.js'
import { useFormState, useFormDispatch } from './FormContext'

const DEVICE_PIXEL_RATIO = window.devicePixelRatio || 1
const getRatioValue = (value) => value / DEVICE_PIXEL_RATIO

function drawCanvasMessage(ctx, message, x, y, canvasWidth, lineHeight) {
  for (const parts of message) {
    let partWidth = parts.reduce((width, p) => width += ctx.measureText(p.text.toUpperCase()).width, 0)

    const xOffset = (canvasWidth / 2) - (partWidth / 2)
    let currentLine = ''
    let wordOffset = 0

    for (const part of parts) {
      const text = part.text.toUpperCase()
      const words = text.split(' ')

      ctx.fillStyle = part.color

      for (let n = 0; n < words.length; n++) {
        const word = words[n] + ' '

        wordOffset = ctx.measureText(currentLine).width

        currentLine += word

        ctx.fillText(word, xOffset + wordOffset, y)
      }
    }

    y += lineHeight
  }
}

function Preview() {
  const canvas = useRef(null)
  const [canvasReady, setCanvasReady] = useState(false)
  const { name, method, date, time, county } = useFormState()
  const dispatch = useFormDispatch()
  const targetWidth = 460
  const targetHeight = 575

  useEffect(() => {
    const font = new FontFace('founders-grotesk', 'url(/founders-grotesk-web-regular.woff2)')

    font.load().then(() => {
      setCanvasReady(true)
      dispatch({ type: 'setCanvas', payload: canvas.current })
    })

    const ctx = canvas.current.getContext('2d')

    if (DEVICE_PIXEL_RATIO > 1) {
      canvas.current.width = targetWidth * DEVICE_PIXEL_RATIO
      canvas.current.height = targetHeight * DEVICE_PIXEL_RATIO
    } else {
      canvas.current.width = targetWidth
      canvas.current.height = targetHeight
    }

    ctx.scale(DEVICE_PIXEL_RATIO, DEVICE_PIXEL_RATIO)
  }, [canvas, dispatch])

  useEffect(() => {
    if (canvas.current) {
      canvas.current.addEventListener('click', () => {
        const form = document.querySelector('.vote-form')
        const header = document.querySelector('.site-header')

        if (form && header) {
          jump(form, { duration: 500, offset: header.clientHeight * -1 })
        }
      })
    }
  }, [canvas])

  useEffect(() => {
    if (canvasReady) {
      const canvasWidth = getRatioValue(canvas.current.width)
      const canvasHeight = getRatioValue(canvas.current.height)

      // Build and clear shell
      const ctx = canvas.current.getContext('2d')
      ctx.clearRect(0, 0, canvasWidth, canvasHeight)

      ctx.fillStyle = 'white'
      ctx.lineWidth = 3
      ctx.fillRect(0, 0, canvasWidth, canvasHeight)
      ctx.strokeRect(0, 0, canvasWidth, canvasHeight)
      ctx.fillStyle = 'black'
      ctx.lineWidth = 2

      // Build branding
      const headerFooterHeight = 55
      ctx.beginPath()
      ctx.moveTo(0, headerFooterHeight)
      ctx.lineTo(canvasWidth, headerFooterHeight)
      ctx.stroke()

      ctx.beginPath()
      ctx.moveTo(0, canvasHeight - headerFooterHeight)
      ctx.lineTo(canvasWidth, canvasHeight - headerFooterHeight)
      ctx.stroke()

      const headerText = 'PLANPLANVOTE.COM'
      const footerText = `WHAT'S YOUR VOTING PLAN?`
      ctx.font = '24px founders-grotesk'
      ctx.fillText(headerText, (canvasWidth / 2) - (ctx.measureText(headerText).width / 2), 36)
      ctx.fillText(footerText, (canvasWidth / 2) - (ctx.measureText(footerText).width / 2), canvasHeight - 20)

      // Build message
      ctx.font = '56px founders-grotesk'

      const niceDate = date ? format(date, 'MMM. d') : ''

      const message = [
        [
          { text: name === '' ? 'first name' : name, color: name === '' ? '#d8d8d8' : '#ffd720' },
        ],
        [
          { text: 'plans to vote', color: 'black' },
        ],
        [
          { text: method === '' ? 'by method' : method, color: method === '' ? '#d8d8d8' : '#deb5c7' },
        ]
      ];

      if (method === 'By Mail') {
        message.push(
          [
            { text: 'on', color: 'black' },
            { text: date ? niceDate : 'date', color: date ? '#006097' : '#d8d8d8' }
          ]
        )

        message.push(
          [
            { text: 'from', color: 'black' },
          ]
        )
      } else {
        message.push(
          [
            { text: 'on', color: 'black' },
            { text: date ? niceDate : 'date', color: date ? '#006097' : '#d8d8d8' },
            { text: 'at', color: 'black' },
          ]
        )

        message.push(
          [
            { text: time === '' ? 'time' : time, color: time === '' ? '#d8d8d8' : '#96d600' },
            { text: 'in', color: 'black' },
          ]
        )
      }

      message.push(
        [
          { text: county === '' ? 'county' : county, color: county === '' ? '#d8d8d8' : '#ff8773' },
        ]
      )

      drawCanvasMessage(ctx, message, 0, 190, canvasWidth, 45)
    }
  }, [canvasReady, name, method, date, time, county])

  return (
    <div className="preview-wrapper">
      <canvas ref={canvas} width="460" height="575"></canvas>
    </div>
  )
}

export default Preview
